<template>
  <v-container
    fluid
  >
    <v-row
      align="center"
      justify="center"
    >
      <v-col cols="12">
        <v-btn
          color="primary"
          fab
          dark
          to="/pages/linksuteis/create"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        <v-card>
          <v-card-title>
            Links Úteis
            <v-spacer />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="linksuteis"
            :options.sync="options"
            :server-items-length="total"
            :footer-props="footer_props"
            class="elevation-1"
          >
            <template v-slot:body="{ items }">
              <draggable
                tag="tbody"
                @end="handleOrdem(items, $event)"
              >
                <tr
                  v-for="item in items"
                  :key="item.ordem"
                >
                  <td>{{ item.titulo }}</td>
                  <td>{{ item.subtitulo }}</td>
                  <td>{{ item.ordem }}</td>
                  <td>{{ item.ativo }}</td>
                  <td>
                    <router-link :to="`/pages/linksuteis/${item.linksUteisId}`">
                      <v-icon
                        class="mr-2"
                        color="info"
                      >
                        mdi-pencil
                      </v-icon>
                    </router-link>
                    <v-icon
                      color="error"
                      @click="destroy(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </draggable>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="text-h5">
          Você tem certeza?
        </v-card-title>

        <v-card-text>
          O link útil será deletada. Deseja prosseguir?
        </v-card-text>

        <v-card-actions>
          <div class="flex-grow-1" />

          <v-btn
            outlined
            class="ml-4"
            large
            color="error"
            dark
            @click="dialog = false"
          >
            Espere, vou verificar
          </v-btn>

          <v-btn
            outlined
            class="ml-4"
            large
            color="success"
            dark
            @click="confirmDelete()"
          >
            Sim, prossiga
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import draggable from 'vuedraggable'
  export default {
    components: {
      draggable,
    },
    data: () => ({
      itemselected: {},
      linksuteis: [],
      headers: [
        { text: 'Título', value: 'titulo' },
        { text: 'Subtítulo', value: 'subtitulo' },
        { text: 'Ordem', value: 'ordem' },
        { text: 'Ativo', value: 'ativo' },
        { text: 'Ações', value: 'action', sortable: false },
      ],
      footer_props: {
        'items-per-page-options': [5, 10, 15, 20],
      },
      dialog: false,
      total: 0,
      options: {
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        PerPage: 25,
        Page: 1,
        SortAscending: true,
        Term: '',
      },
    }),
    watch: {
      options: {
        handler () {
          // this.load()
        },
        deep: true,
      },
      linksuteis: {
        handler () {
          this.handleOrdem()
        },
        deep: true,
      },
    },
    mounted () {
      this.load()
    },
    methods: {
      load: function () {
        this.filter.PerPage = this.options.itemsPerPage
        this.filter.Page = this.options.page
        const query = JSON.stringify(this.filter)
        this.$http.get('/linksuteis', { params: { query } })
          .then(resp => {
            this.linksuteis = resp.data.rows
            this.isAtivo()
            this.total = resp.data.total
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      edit (item) {
        this.$router.push(`/linksuteis/${item.id}`)
      },
      destroy (item) {
        this.itemselected = item
        this.dialog = true
      },
      confirmDelete () {
        this.$http.delete(`/linksuteis/${this.itemselected.linksUteisId}`)
          .then(resp => {
            this.$toast.success('Operação realizada com sucesso')
            this.dialog = false
            this.load()
          })
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
      },
      isAtivo () {
        this.linksuteis.forEach(link => {
          if (link.ativo === 1) {
            link.ativo = 'Sim'
          } else {
            link.ativo = 'Não'
          }
        })
      },
      handleOrdem (items, event) {
        try {
          if (items) {
            const indexOld = event.oldIndex
            const indexNew = event.newIndex
            items.map(item => {
              if (item.ordem === indexOld) {
                item.ordem = indexNew
                this.put(item)
              }
              items[indexNew].ordem = indexOld
            })
            this.put(items[indexNew])
            this.$toast.success('Operação realizada com sucesso')
          }
        } catch (error) {
          this.$toast.error('Falha ao realizar operação')
        }
      },
      put (item) {
        if (item.ativo === true || item.ativo === 'Sim') { item.ativo = 1 }
        if (item.ativo === false || item.ativo === 'Nao') { item.ativo = 0 }
        this.$http.put('/linksuteis/', item)
          .catch(() => {
            this.$toast.error('Falha ao realizar operação')
          })
          .finally(() => {
            this.load()
          })
      },
    },
  }
</script>

<style>

</style>
